import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">


<path d="M5862 7736 l3 -618 54 -19 c169 -59 302 -196 369 -381 l27 -73 3
-977 2 -978 613 0 612 0 -2 982 c-2 640 -6 1016 -13 1078 -77 655 -538 1242
-1175 1495 -99 39 -321 93 -415 101 l-80 7 2 -617z"/>
<path d="M6647 4462 c-49 -54 18 -137 78 -98 31 20 34 75 7 99 -24 22 -65 21
-85 -1z"/>
<path d="M6911 4453 c-14 -17 -19 -34 -15 -47 8 -27 41 -56 65 -56 28 0 59 36
59 68 0 61 -72 83 -109 35z"/>
<path d="M4467 4454 c-9 -10 -8 -767 2 -782 6 -10 35 -13 117 -10 l109 3 5 38
5 39 25 -27 c34 -38 80 -55 142 -55 70 1 126 27 171 82 54 66 69 117 70 229 0
81 -4 104 -25 150 -47 101 -121 152 -223 152 -49 0 -70 -6 -103 -26 -22 -15
-45 -34 -51 -44 -8 -13 -10 20 -11 120 l0 137 -113 0 c-63 0 -116 -3 -120 -6z
m378 -406 c51 -45 42 -144 -16 -173 -42 -22 -67 -18 -100 14 -24 25 -29 38
-29 79 0 40 5 54 26 76 34 33 83 35 119 4z"/>
<path d="M3240 4431 c-130 -19 -220 -110 -220 -221 0 -72 12 -103 60 -151 36
-36 67 -54 145 -84 128 -49 145 -60 145 -95 0 -28 -15 -40 -49 -40 -22 0 -51
31 -51 53 0 15 -15 17 -125 17 l-125 0 0 -27 c1 -71 67 -155 155 -195 43 -20
66 -23 160 -23 105 0 113 2 165 31 80 45 113 97 118 183 7 124 -32 169 -216
246 -114 48 -139 68 -127 106 9 27 63 23 79 -6 18 -32 24 -34 140 -32 107 2
111 3 114 25 10 67 -68 165 -153 194 -60 20 -153 29 -215 19z"/>
<path d="M3710 4067 c0 -237 9 -285 68 -346 78 -82 229 -79 308 6 26 28 31 23
36 -32 l3 -30 110 0 110 0 3 303 2 302 -114 0 -114 0 -4 -185 c-3 -176 -4
-186 -26 -207 -30 -30 -95 -30 -125 1 -21 20 -22 33 -25 206 l-3 185 -115 0
-114 0 0 -203z"/>
<path d="M5412 4240 c-23 -10 -53 -31 -67 -46 l-25 -27 0 42 0 42 -47 -3 -48
-3 0 -289 0 -289 25 -4 c14 -2 35 -1 47 2 21 5 22 9 25 203 3 175 5 201 23
231 35 59 67 76 145 76 78 0 110 -17 145 -76 18 -30 20 -56 23 -236 l4 -202
41 2 42 3 3 195 c3 209 -3 249 -45 311 -54 80 -193 113 -291 68z"/>
<path d="M6080 4251 c-73 -23 -136 -75 -173 -145 -18 -35 -22 -57 -22 -146 0
-90 4 -111 23 -147 53 -100 138 -153 248 -153 80 0 135 21 179 68 17 18 33 32
37 32 5 0 8 -22 8 -49 l0 -49 45 1 45 1 0 293 0 293 -45 0 -45 0 0 -51 0 -51
-49 44 c-27 24 -67 50 -89 56 -43 13 -123 14 -162 3z m179 -90 c91 -47 136
-150 112 -254 -24 -104 -97 -167 -194 -167 -83 0 -152 45 -183 118 -18 44 -18
160 0 203 42 100 170 148 265 100z"/>
<path d="M7334 4249 c-58 -17 -125 -72 -158 -129 -29 -52 -31 -61 -31 -160 0
-98 2 -109 29 -158 53 -96 133 -142 243 -142 79 0 134 21 178 68 17 18 33 32
37 32 4 0 8 -21 10 -47 l3 -48 43 -3 42 -3 0 295 0 296 -45 0 -45 0 0 -45 c0
-25 -3 -45 -6 -45 -3 0 -26 18 -51 40 -25 22 -62 44 -82 50 -43 12 -124 12
-167 -1z m213 -103 c118 -78 119 -295 3 -372 -75 -50 -186 -43 -247 16 -69 64
-86 201 -36 291 53 98 185 128 280 65z"/>
<path d="M6642 3958 l3 -293 48 -3 47 -3 0 295 0 296 -50 0 -50 0 2 -292z"/>
<path d="M6910 3879 c0 -221 -4 -378 -10 -388 -6 -12 -26 -21 -55 -25 -45 -7
-45 -7 -45 -48 l0 -41 57 7 c32 4 71 15 88 24 59 35 60 40 63 460 l3 382 -51
0 -50 0 0 -371z"/>
<path d="M5274 3351 c-225 -36 -425 -173 -485 -331 -30 -80 -24 -204 15 -279
65 -126 199 -225 376 -277 53 -15 96 -19 230 -19 154 1 170 3 245 29 117 40
192 86 263 160 85 89 114 155 115 262 0 67 -5 91 -27 139 -65 142 -206 247
-406 300 -66 18 -255 27 -326 16z m178 -400 c104 -116 -43 -286 -173 -198 -61
41 -71 147 -18 203 48 51 143 48 191 -5z m286 15 c13 -13 18 -12 42 6 53 39
139 18 160 -38 6 -14 10 -66 10 -115 0 -87 -1 -89 -24 -89 -23 0 -24 3 -28 91
-5 100 -16 119 -67 119 -50 0 -56 -12 -59 -115 -4 -93 -5 -95 -28 -95 -23 0
-24 2 -24 78 0 98 -13 126 -62 130 -54 5 -68 -22 -68 -125 0 -81 -1 -83 -24
-83 -20 0 -24 6 -28 43 -3 23 -4 60 -3 82 1 22 3 61 4 88 1 43 3 47 26 47 14
0 25 -4 25 -10 0 -5 14 -3 31 4 36 15 93 7 117 -18z m-577 -5 c16 -16 29 -34
29 -40 0 -17 -56 -13 -70 4 -16 20 -74 19 -94 -1 -22 -21 -21 -102 1 -126 21
-24 68 -23 93 2 36 36 83 21 59 -18 -31 -49 -112 -67 -169 -37 -44 23 -63 67
-58 133 4 47 10 60 37 84 26 24 40 28 87 28 48 0 59 -4 85 -29z m-256 -196 c0
-25 -4 -30 -24 -30 -32 0 -49 28 -31 50 7 9 23 15 34 13 16 -2 21 -10 21 -33z"/>
<path d="M5304 2919 c-11 -13 -19 -35 -19 -59 0 -74 70 -106 120 -55 35 34 30
101 -9 127 -22 15 -73 8 -92 -13z"/>
</g>
</svg>



		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
